<ng-container *transloco="let t">
  <div class="dropdown dropdown-sm" *ngIf="fUser">
    <ng-container *ngIf="fUser.photoURL">
      <img class="user rounded-circle clickable"
           referrerpolicy="no-referrer"
           src="{{fUser.photoURL}}" data-bs-toggle="dropdown"/>
    </ng-container>
    <ng-container *ngIf="!fUser.photoURL">
      <div class="user clickable d-flex align-items-center justify-content-center rounded-circle bg-info" data-bs-toggle="dropdown">
        <fa-icon class="text-white-50" [icon]="faUser"></fa-icon>
      </div>
    </ng-container>


    <div class="dropdown-menu" *ngIf="user">
      <div class="dropdown-item-text">
        <div class=" font-xs text-muted">
          EMAIL
        </div>
        <div>
          {{user.email}}
        </div>
      </div>

      <div class="dropdown-item-text">
        <div class=" font-xs text-muted">
          ORGANIZATION
        </div>
        <div *ngIf="user.organizations.length === 1">
          {{user.organization}}
        </div>
        <div *ngIf="user.organizations.length > 1">
          <select class="form-select form-select-sm" [ngModel]="user.organization" (change)="selectOrganization($any($event.target).value)">
            <ng-container *ngFor="let org of user.organizations">
              <option [disabled]="org === user.organization">{{ org }}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div *ngIf="organization && ['nbcrna','abfas'].includes(organization)" class="dropdown-item clickable" (click)="showUserProfile()">
        {{ t('userMenu.profile') }}
      </div>

      <div class="dropdown-divider"></div>
      <div class="dropdown-item">
        <span (click)="copyIdToken()">Copy ID token</span>
      </div>


      <div class="dropdown-divider"></div>
      <span class="dropdown-item clickable" (click)="logout()">Sign out</span>
    </div>
  </div>
</ng-container>
